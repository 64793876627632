@import '../../style/media';

.panda-doc-iframe {
  &__close-btn {
    &--background {
      background: white;
      position: absolute;
      right: 16px;
      top: 5px;
      width: 170px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &--btn {
      background: #59b671;
      border: none;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .modal-body {
    width: 90vw;
    height: 80vh;

    .modal-body__close-btn {
      display: none;
    }

    @include media-narrow {
      width: 100vw;
      height: 100vh;
      max-height: 100%;
    }
  }

  @include media-narrow {
    display: block;
  }
}
