@import '../../style/media';
@import '../../style/animations';

.signing-step-modal {
  overflow-y: auto;
  background-color: #fff;
  width: 550px;

  @include media-narrow {
    min-width: 100vw;
    max-width: 100vw;
  }

  &__amount-step {
    .esign-radio-button {
      padding: 20px 30px;
      box-sizing: border-box;
      border: 1px solid #e5e5ea;
      border-radius: 8px;
      transition: background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out;
      font-size: 18px;
      font-weight: 500;
      height: 82px;
      &:hover {
        border-color: #d1d1d6;
        background-color: #f2f2f7;
      }
      &.checked_option {
        border: 2px solid #3284ff;
      }
      &__label {
        padding-left: 0 !important;
        &-custom {
          display: flex;
          align-items: center;
          width: 100%;
          span {
            width: 60%;
            flex: 1;
          }
        }
      }
      &__check-mark {
        display: none;
        left: 20px !important;
      }
    }
  }

  &__where-to-send {
    &--divider {
      position: relative;
      padding: 36px 0;
      &-text {
        position: absolute;
        display: inline-block;
        padding: 4px 120px;
        text-align: center;
        background-color: #fff;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
    .signup-button {
      height: 54px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    .esign-modal__title {
      flex: 1;
      margin-bottom: 0;
    }
    @include media-narrow {
      flex-direction: column;
      align-items: flex-start;
      .esign-modal__title {
        margin-bottom: 10px;
      }
    }
  }
  &__order-switch {
    display: flex;
    align-items: center;
    .switch {
      display: inline-block;
      margin-right: 8px;
      input[type='checkbox']:checked + .switch__lever {
        background: #3284ff;
      }
    }
  }
  &__footer {
    align-items: center;
    &--left {
      flex: 1;
      .checkbox {
        .checkbox__mark {
          top: 4px;
        }
        input:checked ~ .checkbox__mark {
          background: #3284ff !important;
        }
      }
    }

    @include media-narrow {
      &--left {
        margin-bottom: 16px;
      }
      button {
        width: 100%;
      }
    }
  }
}

.LEFT_TO_RIGHT {
  @include animation(fadeInLeft, 0.3s, ease-in-out);
}

.RIGHT_TO_LEFT {
  @include animation(fadeInRight, 0.3s, ease-in-out);
}

.verification-email-modal {
  min-width: 500px;
  max-width: 500px;

  &--title {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 23px;
  }

  &--subtitle {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    @include media-below-medium {
      align-items: flex-start;
    }
  }

  &--description {
    margin-bottom: 30px;
  }

  &--input {
    margin-bottom: 30px;
  }

  &--buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      &:first-child {
        margin-right: 20px;
      }
    }

    @include media-below-medium {
      width: 100%;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;

      button {
        width: 100%;
        margin-bottom: 10px;

        &:first-child {
          margin-right: 0px;
        }
      }
    }
  }

  @include media-below-medium {
    min-width: 100vw;
    max-width: 100vw;
  }
}
