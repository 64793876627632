@import 'colors';
@import 'mixin';

.root .esign-select-list {
  background: #ffffff !important;
  border: none;
  border-radius: 6px;
  font-size: 12px;

  &-wrapper {
    position: relative;
  }

  &__label {
    position: absolute;
    top: 3px;
    font-size: 14px;
  }

  &__error-msg {
    position: absolute;
    right: 0;
    top: 3px;
    color: red;
  }

  &__menu-list {
    padding: 0;
  }

  &__single-value {
    line-height: 26px;
    font-size: 12px;
  }

  &__placeholder {
    font-size: 12px;
  }

  &__control {
    height: 40px;
    padding: 5px 0;
    background: transparent;
    border: 0.5px solid #d8d8d8;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      border: 0.5px solid #8b8b8b;
    }

    &--is-focused {
      box-shadow: none;
    }
  }

  &__value-container {
    padding: 2px 12px;
  }

  &__input {
    input {
      color: transparent;
    }
  }

  &__indicators {
    margin-right: 5px;

    .esign-select-list__indicator-separator {
      display: none;
    }

    .esign-select-list__dropdown-indicator {
      padding: 4px;
      border-radius: 50%;

      svg {
        path {
          fill: $primary-color-black;
        }
      }
    }
  }

  &__option {
    cursor: pointer;

    &:active {
      background: #dcdcdc;
    }

    &--is-focused {
      background: #f2f2f2;
    }

    &--is-selected {
      position: relative;
      background: #f2f2f2 !important;
      color: $primary-color-black;
    }
  }
}

.disabled {
  pointer-events: none;

  .esign-select-list__control {
    .esign-select-list__value-container {
      .esign-select-list__single-value {
        color: gray !important;
      }
    }

    .esign-select-list__indicators {
      .esign-select-list__indicator {
        svg {
          path {
            fill: gray !important;
          }
        }
      }
    }
  }
}
