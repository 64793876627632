.wp-documents-library-modal {
  min-width: 900px;
  max-width: 900px;
  min-height: 900px;
  max-height: 900px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &--header {
    width: 100%;
    height: 66px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .title {
      font-size: 19px;
      font-weight: bold;
    }

    .search-bar {
      width: 604px;
      height: 43px;

      .esign-search {
        &__input {
          height: 43px;
          border: none;
          background-color: white;
          border-radius: 4px;
        }

        &__icon {
          right: 12px;
          top: 50%;
          transform: translate(0px, -50%);

          svg {
            width: 19px;
            height: 19px;

            path {
              stroke: #8b8b8b;
            }
          }
        }
      }
    }

    .close-btn {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: #c7c7cc;
        }
      }
    }
  }

  &--containers {
    width: 100%;
    height: 834px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;

    &__left-container {
      width: 30%;
      height: 100%;
      padding: 15px 0px 15px 15px;
      box-sizing: border-box;

      .item {
        width: 100%;
        height: 34px;
        font-size: 14px;
        font-weight: normal;
        padding: 0px 0px 0px 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        p {
          width: fit-content;
          max-width: 90%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &--active {
          background: rgb(244, 244, 248);
          font-weight: bold;
        }

        &:hover {
          background: rgb(244, 244, 248);
        }
      }
    }

    &__right-container {
      width: 70%;
      height: fit-content;
      min-height: 100%;
      max-height: 100%;
      padding: 15px;
      margin-top: 5px;
      box-sizing: border-box;
      background: white;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;

      .esign-loader {
        width: 100% !important;
        height: 90% !important;
        position: absolute;

        div {
          border-color: #3284ff transparent transparent transparent !important;
          border-width: 4px !important;
        }
      }

      .item {
        width: 100%;
        height: 110px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;

        &--img {
          width: 80px;
          min-width: 80px;
          height: 100%;
          margin-right: 15px;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
          transition: all 0.3s ease-in-out;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }

          &:hover {
            box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.35);
          }
        }

        &--title-description {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          &__title {
            font-size: 16px;
            color: #3284ff;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          &__description {
            font-size: 11px;
          }

          &__buttons {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .esign-button {
              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 905px) {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;

    &--header {
      .search-bar {
        width: 534px;
      }

      .close-btn {
        margin-left: 15px;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 655px) {
    &--containers {
      height: 100%;
      min-height: 834px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &__left-container {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: visible;

        .item {
          width: fit-content;

          p {
            width: fit-content;
            max-width: 100%;
          }
        }
      }

      &__right-container {
        width: 100%;
        min-height: 800px;

        .item {
          height: 150px;

          &--img {
            width: 100px;
            min-width: 100px;
          }
        }
      }
    }
  }
}

// .modal-body__close-btn{
//   display: none !important;
// }
