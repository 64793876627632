@import '../../../style/media.scss';
@import '../../../style/colors';

.signing-limit-reached {
  min-width: 550px;
  max-width: 550px;

  &__title {
    font-size: 32px;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 16px;
    margin: 30px 0px;
    line-height: 24px;
    display: flex;

    svg {
      width: 40px;
      margin-right: 10px;
    }
  }

  &__description {
    p {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 14px;

      svg {
        width: 19px;
        height: 19px;

        &:first-child {
          margin-right: 10px;

          g {
            opacity: 1;

            path {
              stroke-width: 1px;
            }
          }
        }

        &:not(:first-child) {
          margin: 0px 10px;
        }
      }

      @include media-below-medium {
        font-size: 12px;

        svg {
          &:not(:first-child) {
            margin: 0px 0px 0px 10px;
          }
        }
      }
    }
  }

  .esign-button {
    padding: 9px 100px;
    margin: 50px auto 0 auto !important;
    font-weight: normal;

    @include media-below-medium {
      padding: 10px 20px;
    }
  }

  &__footer {
    font-size: 12px;
    color: #8b8b8b;
    text-align: center;
    margin-top: 10px;
  }

  @include media-below-medium {
    min-width: 100vw;
    max-width: 100vw;
  }
}
