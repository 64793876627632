@import '../../style/colors';
@import '../../style/media';
@import '../../style/mixin';
@import '../../style/animations';

$table-bg: #343a40;
$table-border-color: #dee2e6;
$table-border-color-dark: #454d55;
$striped-bg: rgba(0, 0, 0, 0.05);
$hover-bg: #fafafa;

.settings-breadcrumbs {
  margin-bottom: 10px;
  @include animation(fadeIn, 0.3s, ease-in-out);

  &__separator {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 6px;
    margin-right: 12px;
    margin-bottom: 2px;
    border-right: 3px solid #272f3b;
    border-bottom: 3px solid #272f3b;
    transform: rotate(-45deg);
  }

  a {
    display: inline-block;
    color: #8b8b8b;
    @include main-font-family();
    font-size: 26px;
    text-decoration: none;

    &:last-child {
      color: #000;
      &:not(:first-child) {
        @include animation(fadeInRight, 0.3s, ease-in-out);
      }
    }
  }
}

.sub-settings {
  &--flex {
    display: flex;
    flex-wrap: wrap;
  }

  @include media-below-wide {
    flex-direction: column;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  &__main-content {
    width: 60%;

    @include media-below-wide {
      width: 100%;
    }
  }

  &__right-sidebar {
    width: calc(40% - 20px);
    margin-left: 20px;

    @include media-below-wide {
      width: 100%;
      margin-left: 0;
    }
  }

  &__form-label {
    margin-bottom: 10px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__toggle {
    &_contacts {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .switch input[type='checkbox']:checked + .switch__lever {
        background: #3284ff;
      }
      p {
        margin-left: 1rem;
      }
    }
    &_notifications,
    &_signing-process {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .switch input[type='checkbox']:checked + .switch__lever {
        background: #3284ff;
      }
      p {
        margin-left: 1rem;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .esign-button {
      & + .esign-button {
        margin-left: 20px;
      }
    }
  }
}

.settings {
  position: relative;
  margin-top: 30px;
  z-index: 2;
  @include animation(fadeIn, 0.3s, ease-in-out);

  .trash_subtitle {
    position: absolute;
    top: -20px;
    font-size: 18px;
    color: $active-fill-icon;

    @include media-narrow {
      top: 0px;
    }
  }

  &-export {
    .settings__block {
      @include media-below-medium {
        padding-top: 58px;
      }
    }
  }

  @include media-narrow {
    margin-top: 0;
    padding-top: 10px;
  }

  &__storage {
    position: absolute;
    right: 0;
    top: -60px;

    @include media-narrow {
      position: static;
      margin: 15px 0;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    @include animation(fadeIn, 0.3s, ease-in-out);

    &:not(:last-of-type) {
      border-bottom: 2px solid $primary-color-black;
    }

    hr {
      width: 100%;
      height: 2px;
      background: #979797;
      border: 1px solid #979797;
    }

    @include media-narrow {
      padding: 20px 0px;
    }

    .plan-card-wrapper {
      justify-content: flex-start;
    }
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  &__title {
    padding-left: 0;
  }

  &__subtitle {
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: normal;
    &_notifications,
    &_signing-process {
      margin-top: 1rem;
    }
    @include media-narrow {
      padding-left: 0;
    }
  }

  &__sub-subtitle {
    font-size: 16px;
    padding-bottom: 15px;

    .description {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 30px;

      &__left-block {
        min-width: 120px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-right: 1px solid #d8d8d8;

        p {
          color: gray;
          font-size: 14px;
        }
      }

      &__right-block {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 7px 0 7px 20px;

        p {
          color: black;
          font-size: 14px;
        }
      }
    }
  }

  &__input {
    width: 350px;

    .esign-input-wrapper {
      &:first-child {
        margin-top: 0px;
      }
    }

    .esign-button {
      width: 100%;
      height: fit-content;
      justify-content: flex-end;
      margin-top: 10px;
      font-size: 14px;
    }
  }

  &__save {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  &__delete {
    display: flex;
    flex-direction: column;

    .settings__subtitle {
      padding-bottom: 5px;
    }

    button {
      width: fit-content;
      margin-top: 25px;
    }

    p {
      font-size: 12px;
      color: #979797;
    }
  }

  &__reset {
    button {
      margin-bottom: 35px;
      width: 140px;
    }
  }

  &__current-plan {
    margin-bottom: 60px;

    &--description {
      height: 70px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;

      .left-section {
        width: 21%;
        font-size: 14px;
        color: gray;
        border-right: 0.5px solid #d8d8d8;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;

        p {
          svg {
            vertical-align: middle;
            margin-left: 4px;
            path {
              fill: #808080;
            }
          }
        }

        @include media-below-medium {
          width: 40%;
        }
      }

      .right-section {
        width: 52%;
        font-size: 14px;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 25px;
      }

      .sub-settings__toggle
        .switch
        input[type='checkbox']:checked
        + .switch__lever {
        background: #3284ff;
      }
    }
    &--premium {
      height: 100px;
    }

    &--btns {
      display: flex;
      align-items: center;
      margin-top: 30px;

      //.cancel-btn {
      //  background: red;
      //}

      button {
        &:first-child {
          margin-right: 20px;

          @media (max-width: 320px) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &__address {
    margin-bottom: 60px;

    &--description {
      height: 100px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;

      .left-section {
        width: 14%;
        font-size: 14px;
        color: gray;
        border-right: 0.5px solid #d8d8d8;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        @include media-below-medium {
          width: 40%;
        }
      }

      .right-section {
        width: 52%;
        font-size: 14px;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;
        margin-left: 25px;
      }
    }
  }

  &__paymethod {
    margin-bottom: 60px;

    &--description {
      height: 70px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;

      .left-section {
        width: 16%;
        font-size: 14px;
        color: gray;
        border-right: 0.5px solid #d8d8d8;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;

        @include media-below-medium {
          width: 40%;
        }
      }

      .right-section {
        width: 52%;
        font-size: 14px;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;
        margin-left: 25px;
      }
    }
  }

  &__select {
    width: 350px;
    margin-top: 10px;
    margin-bottom: 15px;
    &__margin {
      margin-bottom: 2rem;
    }
    .esign-select-list {
      &__control {
        height: 50px;
      }

      &__menu {
        &-list {
          max-height: 140px;

          @include media-below-medium {
            max-height: 120px;
          }
        }
      }
    }
  }

  &__auth {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    p {
      margin-left: 22px;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
  }

  &__device {
    max-width: 800px;
    max-height: 400px;
    border: 1px solid rgba($color: #000000, $alpha: 0.25);
    border-radius: 8px;
    overflow: scroll;

    table {
      width: 100%;

      tr {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.25);

        &:not(:first-child) {
          &:hover {
            cursor: pointer;
            background: #f2f2f2;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        th {
          height: 27px;
          font-size: 14px;
          text-align: left;
          vertical-align: middle;
          font-weight: 500;

          &:first-child {
            padding-left: 20px;
          }

          &:last-child {
            padding-right: 20px;
          }
        }

        td {
          height: 46px;
          font-size: 12px;
          vertical-align: middle;

          &:first-child {
            padding-left: 20px;
          }

          &:last-child {
            padding-right: 20px;
          }
        }
      }
    }

    &--details {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .icon {
        width: 50px;
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-right: 23px;
      }

      .description {
        .type {
          font-size: 18px;
          margin-bottom: 6px;
        }
        .time_and_location {
          font-size: 14px;
          color: #8b8b8b;
        }
      }
    }
  }

  &__form {
    width: 678px;
    margin-bottom: 50px;
  }

  &__save-card {
    margin-bottom: 200px;
  }

  &__sync {
    margin-top: 15px;

    .sync_croup {
      display: flex;

      .icon {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100px;
        }

        @media (max-width: 320px) {
          width: 80px;
        }
      }

      .description {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 30px;

        &__left-block {
          min-width: 120px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          border-right: 1px solid #d8d8d8;

          p {
            color: gray;
            font-size: 14px;
          }
        }

        &__right-block {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin: 7px 0 7px 20px;

          p {
            color: black;
            font-size: 14px;
          }
        }
      }

      @media (min-width: 320px) and (max-width: 575px) {
        flex-direction: column;

        .icon {
          width: 150px;
          height: 150px;
          margin: 0 auto;

          svg {
            width: 100%;
            height: 100%;
            margin-bottom: 20px;
          }
        }

        .description {
          margin: 0px;
          justify-content: center;

          &__left-block {
            min-width: 110px;
          }

          &__right-block {
            margin: 7px 0 7px 15px;
          }
        }
      }
    }

    .buttons_croup {
      display: flex;
      margin-top: 35px;
      margin-bottom: 50px;

      button {
        &:first-child {
          margin-right: 20px;

          @media (max-width: 320px) {
            margin-right: 10px;
          }
        }
      }

      @media (min-width: 320px) and (max-width: 575px) {
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__billing-history {
    width: 70%;
    border: 1px solid #e5e5ea;
    box-sizing: border-box;
    border-radius: 6px;

    @media (max-width: 575px) {
      width: 100%;
      overflow-y: scroll;
    }

    table {
      width: 100%;

      tr {
        height: 36px;
        border-bottom: 0.5px solid #e5e5ea;

        &:first-child {
          height: 25px;
        }

        &:last-child {
          border: none;
        }

        th {
          font-size: 14px;
          text-align: left;
          vertical-align: middle;
          font-weight: normal;

          &:first-child {
            padding-left: 20px;
          }

          &:last-child {
            padding-right: 20px;
            text-align: right;
          }

          @media (max-width: 375px) {
            padding: 0px 5px;

            &:first-child {
              padding-left: 5px;
            }
            &:last-child {
              padding-right: 5px;
            }
          }
        }

        td {
          font-size: 12px;
          vertical-align: middle;

          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
            text-align: right;

            svg {
              cursor: pointer;
              margin-right: 4px;

              path {
                stroke: #3284ff;
              }
            }
          }

          .dot {
            width: 5px;
            height: 5px;
            background: #2bd982;
            border-radius: 50%;
            display: inline-table;
            vertical-align: middle;
          }

          @media (max-width: 375px) {
            padding: 0px 5px;

            &:first-child {
              padding-left: 5px;
            }
            &:last-child {
              padding-right: 5px;
            }
          }
        }
      }
    }
  }

  &__export-padding {
    padding-top: 20px;
  }
  &__export {
    // margin-top: 30px;
    .esign-button {
      svg {
        margin-right: 10px;

        path {
          fill: white;
        }
      }
    }

    .description {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 25px;

      &__left-block {
        min-width: 65px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-right: 1px solid #d8d8d8;

        p {
          color: gray;
          font-size: 14px;
        }
      }

      &__right-block {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 0 0 15px;

        p {
          color: black;
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }

    @media (min-width: 320px) and (max-width: 575px) {
      margin-top: 0px;
    }
  }

  &__btn {
    padding: 10px 15px;
    text-decoration: none;
  }

  &__item {
    min-height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
    color: $primary-color-black;
    font-size: 18px;

    @include media-narrow {
      font-size: 14px;
    }

    &:hover {
      background: $primary-color-grey-light;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #eaeef1;
    }

    &--clickable {
      cursor: pointer;
    }

    button {
      border: none;
      background: none;
      font-size: 18px;
      font-weight: bold;

      @include media-narrow {
        font-size: 14px;
      }

      &.primary {
        color: #4888fa;
      }

      &.danger {
        color: #f15642;
      }
    }
  }

  .option {
    &__name {
      box-sizing: border-box;
      flex-basis: 40%;
      padding-left: 20px;

      @include media-narrow {
        padding-left: 0;
      }

      &--grow {
        flex-grow: 1;
      }
    }

    &__value {
      flex-basis: 40%;

      &--grow {
        flex-grow: 1;
      }

      &--uppercase {
        text-transform: uppercase;
      }

      .storage-remaining {
        width: 80%;
      }

      .esign-radio-group {
        display: flex;
        align-items: center;
        margin: 0;

        .esign-radio-button:not(:last-of-type) {
          margin-bottom: 0;
          margin-right: 20px;

          @include media-narrow {
            margin-right: 10px;
          }
        }

        .esign-radio-button__label {
          padding-left: 30px;
        }
      }
    }

    &__arrow {
      box-sizing: border-box;
      display: flex;
      flex-basis: 20%;
      padding: 0 20px 0 0;
      text-align: left;

      @include media-narrow {
        padding-right: 0;
      }

      svg {
        width: 25px;
        height: auto;
        fill: $primary-color-grey;
      }
    }
  }

  &__images {
    & + .settings__images {
      margin-top: 35px;
    }
    &--wrapper {
      margin-bottom: 100px;
    }
    &-list {
    }
    &--item {
      display: flex;
      align-items: center;
      & + .settings__images--item {
        margin-top: 10px;
      }
      &-content {
        border-radius: 8px;
        border: 1px solid #e5e5ea;
        background: #ffffff;
        display: flex;
        align-items: center;
        width: 350px;
        height: 60px;
        box-sizing: border-box;
        padding: 8px 30px;
        &:hover {
          background-color: #f2f2f7;
        }
      }
      &-make-default {
        font-size: 12px;
        font-weight: 500;
        color: #3284ff;
        &:hover {
          text-decoration: underline;
        }
      }
      &-delete {
        width: 36px;
        height: 36px;
        display: inline-block;
        box-sizing: border-box;
        padding: 7px 10px;
        border-radius: 18px;
        cursor: pointer;
        margin-left: 10px;
        svg {
          width: 17px;
          height: 22px;
        }
        &:hover {
          background-color: #f2f2f7;
        }
      }
      &-default {
        border-radius: 8px;
        background-color: rgba(5, 158, 29, 0.1);
        font-size: 14px;
        color: #059e1d;
        font-weight: 600;
        padding: 4px 6px;
        user-select: none;
      }
    }
    &--image-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 30px;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .image-add-btn {
      width: 100%;
      max-width: 350px;
      margin-top: 10px;
      color: #3284ff;
      font-size: 16px;
      font-weight: 600;
      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        path {
          fill: none;
        }
        #Path {
          fill: #3284ff;
        }
      }
    }
  }
}

.pay-method-modal {
  min-width: 547px;
  display: flex;
  flex-direction: column;

  .payment-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;

    &__title {
      font-size: 16px;
    }
    &__image {
      max-height: 28px;
    }
  }

  .upload-file-alternative {
    width: 100%;
    display: flex;
    justify-content: center;

    .icon-block-wrapper {
      &:hover {
        cursor: pointer;
      }
    }
  }

  @include mobile-small {
    min-width: 100%;
  }

  @include mobile-mini {
    min-width: 100%;
  }
}

.payment-option {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0;

  &__label {
    font-weight: bold;
    margin-right: 15px;
  }

  &__image {
    max-height: 25px;
    width: auto;
  }
}
.contacts {
  .contacts-header {
    align-items: center;
    display: flex;
    &__headline-wrapper {
      .custom-bread-crumb {
        display: inline-block;
        color: black;
        font-size: 26px;
      }
    }
    &__filter-block {
      margin-left: auto;
    }
  }
  .contacts-info {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    .esign-input-wrapper {
      margin-top: 0;
      margin-left: auto;
      max-width: 350px;
      .esign-input-group {
        &.active {
          input {
            border: 0.5px solid #8b8b8b;
            border-left: 0;
          }
        }
      }
    }
    &--action-menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      > button {
        background: #fff;
        margin-left: 1rem;
        border: 0;
        position: relative;
        width: 36px;
        border-radius: 5px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .tooltip {
          display: none;
          z-index: 1;
          position: absolute;
          left: 50%;
          top: -35px;
          transform: translateX(-50%);
          padding: 4px 7px;
          border-radius: 4px;
          white-space: nowrap;
          background: #000;
          color: #fff;
          font-size: 12px;
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #000;
          }
        }
        &:hover {
          .tooltip {
            display: block;
          }
        }
      }
    }
    &--name {
      display: flex;
      align-items: center;
      > span {
        margin-left: 0rem;
      }
    }
    &--search {
      margin-left: auto;
    }
    &--search-wrapper {
      .esign-input-wrapper {
        margin-top: 0;
        input {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 0px;
        }
      }
      > span {
        border: 0.5px solid #d8d8d8;
        height: 49px;
        width: 49px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        border-right: 0px;
      }
      display: flex;
      align-items: center;
    }
    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
      .esign-input-wrapper {
        max-width: 100%;
        margin-top: 8px;
      }
    }
  }
  .contacts-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    & > .table-bordered {
      border: 0;
    }

    @media (max-width: 575.98px),
      (max-width: 767.98px),
      (max-width: 991.98px),
      (max-width: 1199.98px) {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      & > .table-bordered {
        border: 0;
      }
    }
  }
}
.contacts-new {
  .esign-button-address {
    width: 95px;
  }
  .esign-button-notes {
    width: 83px;
  }
  .esign-button-address,
  .esign-button-notes {
    height: 24px;
    margin-top: 20px;
    font-size: 12px;
  }
  .esign-select-list-country {
    margin-top: 20px;
    .esign-select-list__control {
      height: 50px;
    }
  }
  &--action {
    display: flex;
    justify-content: flex-end;
    width: 710px;
    margin-top: 1.5rem;
    .esign-button--blue {
      margin-left: 1rem;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }
  > b {
    margin-top: 2rem;
    display: block;
    font-weight: 500;
  }
  &--basic {
    width: 710px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem 1rem;
    display: grid;
    @media (max-width: 575px) {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
    }
  }
  &--address {
    width: 710px;
    @media (max-width: 575px) {
      width: 100%;
    }
  }
  &--address-code {
    width: 710px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem 1rem;
    display: grid;
    @media (max-width: 575px) {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
    }
  }
}
.forbidden-permission-body {
  .permission--warning {
    margin-top: 30px;
    display: flex;
    align-items: center;
    font-size: 16px;
    svg {
      margin-right: 10px;
      path {
        fill: #000;
      }
    }
  }
  .forfeit-link {
    color: $danger;
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
    display: block;
    text-decoration: none;
    background: transparent;
    border: none;
    &:hover,
    &:active {
      color: $danger-light;
    }
  }
}

@media (max-width: 1080px) {
  .trash_subtitle {
    > span {
      display: none;
    }
  }
}
