.not-found {
  margin-top: 100px;

  &__img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    object-fit: contain;
    cursor: default;
    g > g > path {
      fill: #afafb5;
    }
    &.share--icon {
      g > #Shape {
        fill: #afafb5;
      }
    }
  }

  &__headline,
  &__text {
    text-align: center;
    color: #afafb5;
  }

  &__headline {
    margin-top: 40px;
    font-size: 24px;
    font-weight: 600;
  }

  &__text {
    margin-top: 13px;
    font-size: 18px;
  }
  .not-found-action {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .esign-button-templates-not-found {
    margin-top: 2rem;
    width: 350px;
    font-weight: 500;
  }
}
