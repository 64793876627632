.esign {
  &-tooltip {
    color: #000;
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.25);
    background-color: #000 !important;
    border-radius: 4px;
    padding: 2px 8px !important;
    &:after {
      background-color: #000 !important;
    }
    &.show {
      opacity: 1;
    }
    &:before {
      display: none;
    }
  }
}
