@import '../../style/colors';
@import '../../style/mixin';
@import '../../style/media';

.signers-form-v2 {
  &__add-signer {
    &-block {
      position: relative;
      background-color: #f9fafc;
      border-radius: 8px;
      padding: 15px 15px 15px 30px;
      margin-bottom: 15px;
      box-sizing: border-box;
      .signers-order {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 31px;
        height: 31px;
        border: 3px solid #c7c7cc;
        position: absolute;
        background-color: #fff;
        box-sizing: border-box;
        z-index: 1;
        border-radius: 50%;
        font-size: 20px;
        left: -9px;
        top: -8px;
      }
      &--color-tag {
        position: absolute;
        height: 100%;
        width: 15px;
        left: 0;
        top: 0;
        border-radius: 8px 0 0 8px;
      }
      &--error {
        border: 1px solid #ff453a;
      }

      .esign-input-wrapper--error {
        margin-bottom: 25px;
      }
    }
    &--drag-handle {
      cursor: move;
      margin-right: 8px;
    }
    &--content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &--form-group {
      flex: 1;
    }
    &--type {
      background: #f9fafc;
      border: none !important;
      font-size: 16px;
      padding: 6px;
      font-family: 'SFProText', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      font-weight: 500;
      &:focus {
        outline: none;
        border: none;
      }
      &.selectable:hover,
      &.selectable:focus {
        background-color: #efeff4;
        border-radius: 6px;
      }
    }
  }
  &__title {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  .add-signer-btn {
    @include flex-centered();
    width: 100%;
    margin: 10px 0;
    padding: 13px;
    background: #fff;
    @include main-font-family();
    border: 1px solid #c7c7cc;
    border-radius: 6px;
    color: #3284ff;
    font-size: 16px;
    font-weight: 600;
    svg {
      margin-right: 5px;
      width: 15px;
      height: 15px;
      path {
        stroke: #3284ff;
      }
    }

    &:hover,
    &:focus {
      background: #f9fafc;
    }
  }

  &__add-signer-block {
    position: relative;

    .input-field-wrapper {
      flex: 1 1 auto;
      width: 100%;
      position: relative;
      &-me {
        width: 100%;
        .esign-input-wrapper {
          width: 100%;
        }
      }
      &-name {
        display: flex;
        align-items: center;
        width: 100%;
        .contact-action {
          position: absolute;
          cursor: pointer;
          right: 15px;
          bottom: 12px;
        }
        > .esign-contact-input {
          width: 100%;
        }
      }
      .esign-input__error-msg {
        padding-right: 15px;
      }
    }

    .remove-signer-btn {
      @include flex-centered();
      position: absolute;
      padding: 0;
      top: 14px;
      right: 17px;
      width: 18px;
      height: 18px;
      background: $primary-color-grey;
      border: none;
      border-radius: 6px;

      & > svg {
        width: 14px;
        height: 14px;
      }

      &:hover,
      &:focus {
        background: #8b8b8b;
      }
    }
  }

  &__signers-block {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 6px;
    overflow: hidden;
  }

  &__signer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #eaeaea;

    &--padding-small {
      padding: 10px 15px;
    }

    .signer-name {
      font-size: 14px;
      color: $primary-color-black;
    }

    .signer-email {
      font-size: 14px;
      color: #8c8c8c;
    }

    .delete-btn {
      @include flex-centered();
      padding: 5px;
      background: #ebdbd9;
      border: none;
      border-radius: 50%;

      svg {
        width: 25px;
        height: auto;
      }
    }
  }
}
.esign-input__error-msg {
  &.esign-input__error-msg-custom {
    position: relative;
    bottom: 0;
    top: -18px;
    @include media-narrow {
      white-space: break-spaces;
      text-align: left;
      display: block;
    }
  }
}
