.custom-scroll {
  &::-webkit-scrollbar {
    display: block;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f7;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    border-radius: 4px;
    background-clip: content-box;
    background-color: rgba(#3e3e3e, 0.1);
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(#3e3e3e, 0.2);
  }
}

.hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }
}
