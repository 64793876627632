.preview-wp-document-modal {
  min-width: 697px;
  max-width: 697px;
  min-height: 692px;
  max-height: 692px;
  padding: 0px;
  background: #f2f2f7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &--header {
    width: 100%;
    height: 66px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .buttons-group {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .esign-button {
        &:first-child {
          margin-right: 15px;
        }
      }
    }

    .close-btn {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: #c7c7cc;
        }
      }
    }
  }

  &--container {
    width: 100%;
    height: 626px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 20px;
    box-sizing: border-box;

    .preview-wrapper {
      width: 100%;
      height: 100%;
      background: #d1d1d6;
      border-radius: 8px;
      padding: 15px;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: auto;

      .react-pdf {
        &__Page {
          &:not(:last-child) {
            margin-bottom: 20px;
          }

          &__annotations {
            display: none;
          }
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
}
