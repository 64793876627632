.esign-loader {
  display: inline-block;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 85px;
  height: 85px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 65px;
    height: 65px;
    border: 8px solid #ffffff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ffffff transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;

  .esign-loader {
    display: inline-block;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 74px;
    height: 74px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      border: 3px solid gray;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: gray transparent transparent transparent;
    }

    & div:nth-child(1) {
      animation-delay: -0.45s;
    }
    & div:nth-child(2) {
      animation-delay: -0.3s;
    }
    & div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
