@import '../../../style/colors';
@import '../../../style/media';
@import '../../../style/mixin';
@import '../../../style/animations';

.custom-modal {
  @include flex-centered();
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 7, 14, 0.7);
  backdrop-filter: blur(3px);
  mix-blend-mode: normal;
  @include animation(fadeIn, 0.5s, linear);

  &--transparent-bg {
    background: transparent;
    backdrop-filter: none;
  }

  &.no-scroll-modal {
    animation: none;

    .modal-body {
      overflow: hidden;
      animation: none;
    }
  }

  .modal-body {
    position: relative;
    max-height: 90vh;
    @include animation(fadeInDown, 0.3s, ease-in-out);

    &__close-btn {
      @include flex-centered();
      position: absolute;
      top: -50px;
      right: 0;
      width: 40px;
      height: 40px;
      background: #464646;
      border: none;
      border-radius: 50%;

      @include media-below-medium {
        width: 40px;
        height: 25px;
        top: 20px;
        right: 20px;
        border-radius: 3px;
      }
    }

    @include media-below-medium {
      max-height: 88vh;
      width: 100% !important;
      position: absolute;
      left: 0p;
      top: 0px;
      overflow: scroll;
    }
  }
}

.without-animation {
  animation: none !important;

  .modal-body {
    animation: none !important;
  }
}

.esign-modal {
  box-sizing: border-box;
  min-width: 550px;
  max-width: 650px;
  max-height: calc(90vh - 0px);
  padding: 26px 35px;
  background: $primary-color-grey-light;
  border-radius: 6px;
  overflow-y: inherit;

  hr {
    height: 2px;
    background: rgb(226, 226, 226);
    border: none;
  }

  &__title {
    font-size: 26px;
    margin-bottom: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;

    svg {
      width: 31px;
      min-width: 31px;
      height: 31px;
      min-height: 31px;
      margin-right: 12px;
    }
  }

  &__subtitle {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 26px;

    svg {
      width: 20px;
      min-width: 20px;
      height: 24px;
      min-height: 24px;
      margin-right: 12px;
    }
  }

  &__description {
    font-size: 16px;
    margin-top: 25px;
  }

  &__banner {
    padding: 15px;
    background: #dbdbdb;
    text-align: center;
    font-size: 16px;
    border-radius: 6px;
  }

  &__paragraph {
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
  }

  &__separator {
    position: relative;
    display: block;
    margin: 25px 0 20px;
    text-align: center;
    text-transform: uppercase;
    border-top: 1px solid #919191;

    span {
      display: block;
      width: 60px;
      margin: -8px auto 0;
      padding: 0 20px;
      color: #919191;
      text-transform: uppercase;
      background: $primary-color-grey-light;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    @include media-narrow {
      flex-direction: column;
      justify-content: center;
    }

    &--centered {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }

    button {
      @include media-narrow {
        margin-bottom: 10px;
      }

      &:not(:last-of-type) {
        margin-right: 20px;

        @include media-narrow {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    min-width: 100vw;
    max-width: 100vw;
    padding: 15px 20px;
  }
}

.cancel-subsription-modal {
  width: 550px;

  @media (max-width: 375px) {
    width: 100vw;
  }

  &__title {
    font-size: 26px;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 16px;
    margin-bottom: 35px;
    display: flex;
    align-items: center;

    svg {
      width: 18px;
      height: 19px;
      margin-right: 12px;
    }
  }

  &__description {
    font-size: 16px;
    margin-bottom: 35px;
    line-height: 22px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      &:first-child {
        margin-right: 15px;
      }
    }

    @media (max-width: 320px) {
      flex-direction: column;

      button {
        width: 100%;

        &:first-child {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
