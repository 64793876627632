@import '../../../style/colors';
@import '../../../style/mixin';
@import '../../../style/media';
@import '../../../style/animations';

.header-search-bar {
  width: 720px;
  position: relative;
  height: 46px;
  &--wrapper {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;

    border: 1px solid #dfe1e5;
    border-radius: 6px;

    background-color: #fff;
    color: #212121;

    font-size: 16px;

    transition: border 0.2s ease-in-out;

    &:hover,
    &:active,
    &:focus-within {
      border: 1px solid $active-fill-icon;
    }
  }
  &--search-icon-btn {
    background: transparent;
    border: 0;
    padding: 0;
  }

  //z-index: 0;
  &--input {
    min-height: 44px;
    width: 100%;

    display: flex;
    align-items: center;
    padding: 0 12px;
    box-sizing: border-box;
    .clear-icon {
      cursor: pointer;
      path {
        fill: #8b8b8b;
      }
    }

    .spinner {
      width: 44px;
      margin-right: -12px;
    }

    > input {
      width: 100%;
      flex: 1;
      padding: 0 0 0 12px;

      border: none;
      outline: none;

      background-color: rgba(0, 0, 0, 0);
      font-size: inherit;
      font-family: inherit;

      color: #212121;

      ::placeholder {
        color: grey;
        opacity: 1;

        :-ms-input-placeholder {
          color: grey;
        }

        ::-ms-input-placeholder {
          color: grey;
        }
      }
    }
  }

  &--list {
    display: contents;
  }

  &--list-item {
    width: 100%;
    padding: 9px 12px;
    background: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 1px solid white;
    font-size: 16px;
    // Ellipsis
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.14);
    }
    svg {
      width: 22px;
      height: 22px;
      min-width: 22px;
      margin-right: 15px;
    }

    .avatar {
      width: 22px;
      height: 22px;
      min-width: 22px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;
      p {
        font-size: 10px;
        transform: none;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

    > div {
      display: flex;
      width: 100%;
      @include media-narrow {
        overflow: hidden;
        flex-direction: column;
        > span {
          width: 100%;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    span {
      font-size: 16px;
      font-weight: 400;
    }
    &--created-at {
      font-size: 12px !important;
      color: $active-fill-icon;
      flex: 1;
      text-align: right;
      @include media-narrow {
        flex: auto;
        text-align: left;
      }
    }
  }

  &--suggest-wrapper {
    width: 100%;
    background: #fff;
    .line {
      border-top-color: rgb(232, 234, 237);
      border-top-style: solid;
      border-top-width: 1px;

      margin: 0 20px 0 14px;

      padding-bottom: 4px;
    }
    @include media-narrow {
      top: 44px;
      position: absolute;
      scrollbar-width: none;
      width: calc(100vw - 20px);
    }
  }

  &--suggest-container {
    max-height: 410px;
    width: 100%;
    overflow: scroll;
    box-sizing: border-box;
    //position: absolute;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @include media-narrow {
    width: 100%;
    &--wrapper {
      background: transparent;
      position: relative;
      border: 0 !important;
      //&:focus input {
      //  width: 100px !important;
      //}
    }
    &--input {
      display: block;
      padding: 0;
      input {
        width: 0;
        padding-left: 10px;
        padding-right: 40px;
        height: 44px;
        box-sizing: border-box;
        transition: all 200ms cubic-bezier(0, 0.11, 0.35, 1);
        animation-direction: reverse;
        float: right;
        //transition: all 100ms linear;
      }
    }
    &--search-icon-btn {
      position: absolute;
      top: 1px;
      right: -14px;
      height: 44px;
      width: 44px;
      z-index: 1111;
      display: flex;
      align-items: center;
    }
    &.active {
      .header-search-bar--input {
        input {
          width: 100%;
          border: 1px solid $active-fill-icon;
          border-radius: 6px;
          background: #fff;
        }
      }
    }
  }
}
