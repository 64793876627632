@mixin mobile-mini {
  @media (min-width: 320px) and (max-width: 375px) {
    @content;
  }
}

@mixin mobile-small {
  @media (min-width: 375px) and (max-width: 575px) {
    @content;
  }
}

@mixin mobile-big {
  @media (min-width: 575px) and (max-width: 768px) {
    @content;
  }
}

@mixin tablet-small {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin tablet-big {
  @media (min-width: 1023px) and (max-width: 1279px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1279px) {
    @content;
  }
}

@mixin media-narrow {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin media-below-medium {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin media-below-wide {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin media-medium-narrow {
  @media (min-width: 576px) and (max-width: 767px) {
    @content;
  }
}

@mixin media-medium {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin media-below-wide-2 {
  @media (min-width: 1023px) and (max-width: 1366px) {
    @content;
  }
}
