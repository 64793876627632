@import '../../style/media';
@import '../../style/animations';

.template-step-modal {
  overflow-y: auto;
  background-color: #fff;
  width: 550px;

  @include media-narrow {
    min-width: 100vw;
    max-width: 100vw;
  }

  &__type-step {
    .esign-modal__title {
      margin-bottom: 34px;
    }
    .esign-radio-group {
      max-width: 100%;
      width: 100%;
      .esign-radio-button:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
    .esign-radio-button {
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid #e5e5ea;
      border-radius: 8px;
      height: 94px;
      transition: background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out;
      &:hover {
        border-color: #d1d1d6;
        background-color: #f2f2f7;
      }
      &.checked_option {
        border: 2px solid #3284ff;
        background: rgba(50, 132, 255, 0.1);
      }
      &__label {
        white-space: break-spaces !important;
        padding-left: 0 !important;
        &-custom {
          display: flex;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          div {
            flex: 1;
            span {
              display: block;
              &:first-child {
                font-size: 18px;
                font-weight: 600;
              }
              &:last-child {
                font-size: 12px;
                font-weight: 400;
                color: #8b8b8b;
                margin-top: 5px;
              }
            }
          }
          svg {
            width: 40px;
            height: 40px;
            path {
              fill: #5e5e5e;
            }
          }
        }
      }
      &__check-mark {
        display: none;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    .esign-modal__title {
      flex: 1;
      margin-bottom: 0;
    }
  }
  &__order-switch {
    display: flex;
    align-items: center;
    .switch {
      display: inline-block;
      margin-right: 8px;
      input[type='checkbox']:checked + .switch__lever {
        background: #3284ff;
      }
    }
  }
  &__footer-signing {
    > .esign-button--light {
      &:nth-child(1) {
        margin-right: auto;
      }
      &:nth-child(2) {
        border: 1px solid #3284ff;
      }
    }
  }
  &__footer {
    align-items: center;
    display: flex;
    &--left {
      flex: 1;
      .checkbox {
        .checkbox__mark {
          top: 4px;
        }
        input:checked ~ .checkbox__mark {
          background: #3284ff !important;
        }
      }
    }

    @include media-narrow {
      &--left {
        margin-bottom: 16px;
      }
      button {
        width: 100%;
      }
    }
  }
}
