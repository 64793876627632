@import '../../../style/colors';

.checkbox {
  display: block;
  position: relative;
  min-width: 16px;
  min-height: 16px;
  margin: 15px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    .checkbox__mark {
      background: #efeff4;
      border: 1.5px solid black;
    }
  }

  &__label {
    padding-left: 30px;
    line-height: 20px;
    white-space: nowrap;
    color: black;
    font-size: 14px;
  }

  &__mark {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1.5px solid #8b8b8b;
    border-radius: 3px;
    transition: 0.2s;

    &:hover {
      background: #efeff4;
      border: 1.5px solid black;
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 55%;
      top: 30%;
      width: 3px;
      height: 7px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform-origin: left;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkbox__mark {
      background: #636363 !important;

      &:after {
        display: block;
      }
    }

    &:checked:disabled ~ .checkbox__mark {
      background: #636363;
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5 !important;
  }
}
