.roles-form-link {
  .esign-select-list--is-disabled {
    opacity: 0.7;
    pointer-events: none;
    background: #f2f2f7;
    border-radius: 6px;
  }
  .radio {
    &.radio-yes {
      align-items: flex-start;
      .esign-radio-group {
        margin-top: 0;
      }
    }
    .radio-label {
      display: flex;
      flex-direction: column;
      margin-left: 0.5rem;
      > span {
        display: flex;
        margin-bottom: 0.5rem;
      }
    }
    display: flex;
    align-items: center;
    > span {
      margin-left: 0.5rem;
    }
  }
}
