.sign-remaining {
  width: 230px;
  padding: 50px 15px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;

  &.mobile {
    padding: 15px;
    position: absolute;
    margin-top: 0px;
    bottom: 0px;
    top: unset;
  }

  &__range {
    width: 100%;
    height: 10px;
    border-radius: 4px;
    overflow: hidden;
    background: white;
    border: 1px solid rgba($color: #8a8a8a, $alpha: 0.25);
    margin: 12px 0px;

    p {
      width: 0%;
      height: 100%;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      position: relative;

      svg {
        cursor: pointer;
      }

      .tooltip {
        display: none;
      }

      &:hover {
        .tooltip {
          font-weight: normal;
          display: block;
          top: -110px;
          color: white;
          text-decoration: none;
        }
      }
    }

    span {
      cursor: pointer;
      color: #3284ff;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }

    &--over {
      font-size: 16px;
      font-weight: bold;
    }

    &--under {
      font-size: 12px;
    }
  }

  .tooltip {
    display: none;
    z-index: 1;
    position: absolute;
    left: 50%;
    top: -35px;
    transform: translateX(-50%);
    padding: 4px 7px;
    border-radius: 4px;
    white-space: nowrap;
    background: #000;
    color: #fff;
    font-size: 12px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #000;
    }
  }

  @media (max-height: 738px) {
    position: relative;
  }
}
