@import '../../style/colors';
@import '../../style/mixin';
@import '../../style/media';
@import '../../style/animations';

.top-nav {
  @include flex-centered();
  position: relative;
  height: 73px;
  padding: 13px 45px;
  background: rgb(239, 239, 244);
  box-sizing: border-box;
  z-index: 7;
  border-bottom: 1px solid rgba($color: #8a8a8a, $alpha: 0.25);

  &-almost-there {
    z-index: 11;
  }
  @include media-narrow {
    padding: 10px;
  }

  .left-side-container {
    display: flex;
    align-items: center;

    @include tablet-small {
      justify-content: flex-start;
    }

    @include tablet-big {
      justify-content: flex-start;
    }
  }

  .logo {
    display: flex;
    text-decoration: none;

    img {
      height: 40px;
      width: auto;

      @media (max-width: 320px) {
        height: 37px;
      }
    }
    p {
      font-size: 7px;
      color: white;
      padding-left: 5px;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    .esign-button.esign-button--login {
      border: 1px solid #e5e5ea;
      background: #fff;
      color: #000;
    }
    &_reminder {
      position: relative;
      margin-right: 1rem;
      > svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
        path {
          fill: #8b8b8b;
        }
      }
    }
    .esign-button {
      background: hsla(0, 0%, 100%, 0.5);

      &:hover {
        > svg {
          path {
            fill: #5e5e5e;
          }
        }
      }
      &_notif_list_wrapper {
        overflow-y: auto;
        height: 408px;
        &.empty {
          height: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &_notif_list_empty {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #afafb5;
        > svg {
          width: 40px;
          height: 42px;
        }
        > span {
          display: flex;
          margin-top: 1rem;
        }
      }
      &_notif_list_item {
        display: flex;
        flex-direction: column;
        padding: 0.25rem 0.75rem;
        padding-bottom: 0.75rem;
        border-bottom: 1px solid rgb(229, 229, 234);
        height: calc(52px);
        cursor: pointer;
        &_footer {
          border-top: 1px solid rgb(229, 229, 234);
          color: #3284ff;
          border-bottom: 0px !important;
          &_read {
            color: #c7c7cc;
          }
          > span,
          b {
            margin-left: auto;
          }
        }
        &_title {
          height: 37px;
        }
        &_footer {
          b {
            font-weight: 500;
            cursor: pointer;
          }
        }
        &_title,
        &_footer {
          padding: 0.5rem 0.75rem;
          display: flex;
          border-bottom: 1px solid rgb(229, 229, 234);
          align-items: center;
          b {
            font-size: 14px;
          }
          .settings-notif {
            margin-left: auto;
            > svg {
              width: 16px;
              height: 16px;
              fill: #8b8b8b;
            }
          }
        }
        &:last-of-type {
          border-bottom: 0px;
        }
        > span {
          padding: 0rem 0rem;
          display: flex;
          font-size: 11px;
          color: #8b8b8b;
          &.time {
            margin-bottom: 0.25rem;
          }
        }
      }
      &_notif_list_item_description {
        display: flex;
        align-items: flex-start;
        position: relative;
        .icon {
          margin-right: 1rem;
          &.icon-profile {
            .avatar {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              > div {
                border-radius: 50%;
              }
            }
            span {
              position: relative;
            }
            img {
              &.profile {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                position: relative;
              }
            }
            svg {
              width: 10px;
            }
            i {
              background: #fff;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border: 1px solid #e5e5ea;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              bottom: -2px;
              right: -6px;
              &.icon-check-svg {
                svg {
                  fill: #059e1d;
                }
              }
            }
          }
          &.icon-check {
            svg {
              top: 5px;
              position: relative;
            }
          }
          svg {
            fill: green;
            width: 28px;
            height: 28px;
          }
        }
        .arrow {
          width: 10px;
          margin-left: auto;
          svg {
            width: 16px;
            height: 20px;
            fill: #afafb5;
            right: 0.75rem;
            position: relative;
          }
        }
        .title {
          font-weight: normal;
          font-size: 12px;
        }
        .sub-title {
          margin-top: 0.125rem;
          display: flex;
          font-size: 11px;
          color: #8b8b8b;
        }
      }
      &_notif_list {
        position: absolute;
        width: 350px;
        height: 494px;
        background: #fff;
        border-radius: 8px;
        left: -320px;
        margin-top: 0.5rem;
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.25);
        &_empty_wrapper {
          height: 200px;
        }
      }
      &_count {
        position: absolute;
        top: -12px;
        right: -12px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #ff453a;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 10px;
        z-index: 111;
      }
    }
    .esign-button {
      background: hsla(0, 0%, 100%, 0.5);
      color: white;

      &:hover {
        background: hsla(0, 0%, 100%, 0.7);
      }
    }

    .esign-search-wrapper {
      margin-right: 41px;

      .esign-search__input {
        border-radius: 40px;
        padding: 10px 28px 10px 10px;

        &::placeholder {
          color: $primary-color-white;
        }
      }

      .esign-search__icon {
        right: 11px;
        top: 10px;
      }

      &--expand {
        width: 50%;
        max-width: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0px);

        .esign-search__input {
          width: 100%;
          border-radius: 8px;
          background: white;
          color: black;

          &::placeholder {
            color: gray;
          }
        }

        svg {
          path {
            stroke: black;
          }
        }

        @media (min-width: 820px) and (max-width: 1023px) {
          width: 40%;
        }

        @media (min-width: 710px) and (max-width: 820px) {
          width: 30%;
        }

        @media (min-width: 575px) and (max-width: 710px) {
          width: auto;
          max-width: 30%;
        }
      }

      @include media-narrow {
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 10px;
        margin-right: 0;
      }

      @include mobile-big {
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 10px;
        margin-right: 0;
      }

      @media (min-width: 320px) and (max-width: 575px) {
        width: 40px;
        left: 50%;
        transform: translate(50%, 0px);

        .esign-search__input {
          width: 40px;
          border-radius: 8px;
          background: white;
          color: black;

          &:focus {
            width: 200px;
          }
        }

        svg {
          path {
            stroke: black;
          }
        }
      }

      @media (min-width: 575px) and (max-width: 768px) {
        left: 50%;
      }
    }

    .user-avatar {
      @include flex-centered();
      width: 40px;
      height: 40px;
      background: #d8d8d8;
      border: none;
      border-radius: 50%;
      margin-left: 41px;
      overflow: hidden;
      cursor: pointer;

      img {
        height: 100%;
      }

      @include mobile-mini {
        margin-left: 0px !important;
      }

      @include mobile-small {
        margin-left: 10px !important;
      }

      @include media-narrow {
        margin-left: 24px;
      }
    }
  }

  &__item {
    background: transparent;
    color: $primary-color-white;
    @include main-font-family();
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    border: none;
    border-radius: 20px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
      path {
        fill: #8b8b8b;
      }
    }

    &:hover {
      svg {
        path {
          fill: #5e5e5e;
        }
      }
    }

    &--active {
      background: #191919;
    }

    &--settings {
      @include media-below-wide {
        display: none;
      }
    }

    @media (max-width: 450px) {
      font-size: 12px;
    }
  }

  form {
    margin-left: auto;
  }
}

.home {
  @include media-narrow {
    padding: 10px 10px 10px 10px;
  }

  @include mobile-big {
    padding: 10px 10px 10px 10px;
  }
}

.is-mobile {
  animation: none !important;

  .side-bar-wrapper {
    left: -99%;
  }

  .burger-btn-wrapper {
    display: block !important;

    svg {
      fill: black;
    }
  }
}

.is-sticky {
  &--open {
    position: sticky;
    top: 0px;
    padding: 0px 45px;
    margin-bottom: 6px;
    background: rgba($color: #000000, $alpha: 0.8);
    animation-duration: 0.3s;
    animation-name: headerFadeInDown;
    animation-timing-function: ease-in-out;

    .esign-search-wrapper {
      .esign-search__input {
        background: transparent;
        color: white;

        &::placeholder {
          color: white;
        }
      }

      .esign-search__icon {
        svg {
          path {
            stroke: white;
          }
        }
      }
    }
  }

  &--close {
    position: sticky;
    top: -80px;
    padding: 13px 45px;
    margin-bottom: 0px;
    background: rgba($color: #000000, $alpha: 1);
    animation-duration: 0.3s;
    animation-name: headerFadeInUp;
    animation-timing-function: ease-in-out;
  }
}

.home {
  @media (min-width: 320px) and (max-width: 575px) {
    .esign-search-wrapper {
      width: 100vw;
      left: 98%;
      transform: translate(-100%, 0px);

      .esign-search__input {
        width: 40px;
        background: transparent;
        border-radius: 40px;
        color: white;

        &:focus {
          width: 95%;
        }
      }

      .esign-search__icon {
        svg {
          path {
            stroke: white;
          }
        }
      }
    }
  }
}

@keyframes headerFadeInDown {
  from {
    top: -50px;
    padding: 13px 45px;
    margin-bottom: 0px;
    background: rgba($color: #000000, $alpha: 1);
  }

  to {
    top: 0px;
    padding: 0px 45px;
    margin-bottom: 5px;
    background: rgba($color: #000000, $alpha: 0.8);
  }
}

@keyframes headerFadeInUp {
  from {
    top: 0px;
    padding: 0px 45px;
    margin-bottom: 5px;
    background: rgba($color: #000000, $alpha: 0.8);
  }

  to {
    top: -80px;
    padding: 13px 45px;
    margin-bottom: 0px;
    background: rgba($color: #000000, $alpha: 1);
  }
}
