@mixin animation(
  $name,
  $duration: 0.3s,
  $function: easy-in-out,
  $delay: 0,
  $direction: normal
) {
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $function;
  animation-delay: $delay;
  animation-direction: $direction;

  -webkit-animation-name: $name;
  -webkit-animation-duration: $duration;
  -webkit-animation-timing-function: $function;
  -webkit-animation-delay: $delay;
  -webkit-animation-direction: $direction;

  -moz-animation-name: $name;
  -moz-animation-duration: $duration;
  -moz-animation-timing-function: $function;
  -moz-animation-delay: $delay;
  -moz-animation-direction: $direction;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translate(50px);
    opacity: 0;
  }

  to {
    transform: translate(0px);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    transform: translate(-50px);
    opacity: 0;
  }

  to {
    transform: translate(0px);
    opacity: 1;
  }
}
