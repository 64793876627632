@import '../../style/colors';
@import '../../style/media';
@import '../../style/mixin';

.footer {
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding-left: 10%;

  @include media-below-medium {
    padding-left: 2%;
    flex-wrap: wrap;
  }

  @include media-narrow {
    flex-direction: column;
  }

  @include tablet-big {
    padding-left: 5%;
  }

  @include tablet-small {
    padding-left: 5%;
  }

  $footer-text-color: #363636;

  &-wrapper {
    padding: 40px 0 20px;
    background: #e6e6e6;
    z-index: 5;

    .copyright-block {
      margin-top: 40px;
      text-align: center;
      font-size: 15px;
      color: $footer-text-color;

      a {
        display: inline-block;
        margin: 0 5px;
        text-decoration: none;
        color: $footer-text-color;

        &:hover {
          color: $primary-color-black;
        }
      }
    }

    &--home-page {
      background: #222;

      .footer__column {
        h3 {
          color: $primary-color-white;
        }
      }

      .footer__link-list {
        a,
        button {
          color: gray;

          &:hover {
            color: #a6a6a6;
          }
        }
      }

      .footer {
        .social-links {
          svg {
            &:hover {
              path {
                fill: #606060;
              }
            }
          }
        }
        .app-store-icon--light {
          border: none;
          box-shadow: none;
        }
      }

      .copyright-block {
        color: $primary-color-white;

        a {
          color: $primary-color-white;

          &:hover {
            color: #a6a6a6;
          }
        }
      }
    }
  }

  &__column {
    flex: 1 1 0;
    min-width: 150px;

    h3 {
      margin-top: 20px;
      margin-bottom: 35px;
      color: $footer-text-color;

      @include media-narrow {
        margin-bottom: 20px;
      }
    }

    a {
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  &__link-list {
    margin: 0;
    padding: 0;

    a,
    button {
      display: inline-block;
      margin-bottom: 15px;
      padding: 0;
      text-decoration: none;
      font-size: 17px;
      color: $footer-text-color;
      background-color: transparent;
      border: 0;
      line-height: 1;
      @include main-font-family();

      &:hover {
        color: $primary-color-black;
      }
    }
  }

  .social-links {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    a {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e6e6e6;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      border-radius: 50%;

      &:first-child {
        margin: 0 17px;
      }

      svg {
        width: 70%;
        height: 70%;

        &:hover {
          path {
            fill: #000;
          }
        }
      }
    }
  }

  .app-store-icon--light {
    width: 133px;
    height: 40px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

    &:hover {
      #Background_Rectangle {
        path {
          fill: #cdcdce;
        }
      }
    }
  }
}
