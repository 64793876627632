@font-face {
  font-family: 'Times New Roman';
  src: local('Times New Roman'), url(./style/fonts/times.ttf) format('truetype');
}

@font-face {
  font-family: 'Savoye LET';
  src: local('Savoye LET'), url(./style/fonts/SavoyeLET.ttc) format('truetype');
}

@font-face {
  font-family: 'SignPainter';
  src: local('SignPainter'),
    url(./style/fonts/SignPainter-HouseScript.ttf) format('truetype');
}

@font-face {
  font-family: 'SnellRoundhand';
  src: local('SnellRoundhand'),
    url(./style/fonts/Snell-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'AppleChancery';
  src: local('AppleChancery'),
    url(./style/fonts/AppleChancery.ttf) format('truetype');
}

@font-face {
  font-family: 'Noteworthy';
  src: local('Noteworthy'),
    url(./style/fonts/Noteworthy-Lt.ttf) format('truetype');
}

@font-face {
  font-family: 'SFProText';
  font-weight: 300;
  src: local('SFProText'),
    url(./style/fonts/SF-Pro-Text-Light.otf) format('truetype');
}

@font-face {
  font-family: 'SFProText';
  font-weight: 400;
  src: local('SFProText'),
    url(./style/fonts/SF-Pro-Text-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'SFProText';
  font-weight: 500;
  src: local('SFProText'),
    url(./style/fonts/SF-Pro-Text-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'SFProText';
  font-weight: 600;
  src: local('SFProText'),
    url(./style/fonts/SF-Pro-Text-Semibold.otf) format('truetype');
}

@font-face {
  font-family: 'SFProText';
  font-weight: 700;
  src: local('SFProText'),
    url(./style/fonts/SF-Pro-Text-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'SFProText';
  font-weight: 900;
  src: local('SFProText'),
    url(./style/fonts/SF-Pro-Text-Heavy.otf) format('truetype');
}
