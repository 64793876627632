@import '../../../style/colors';

.esign-input-contact-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  .spinner {
    margin-top: 1rem;
  }
  .esign-input-group-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .esign-input-group {
      display: flex;
      align-items: center;
      width: 100%;
      &.active {
        .esign-input__icon {
          border: 1px solid #3284ff;
          border-right: 0px;
        }
      }
      > div {
        position: relative;
        width: 100%;
      }
      input {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        min-width: 480px;
        border: 1px solid #d8d8d8 !important;
        border-left: 0px !important;
        &:hover {
          border: 1px solid #3284ff !important;
          border-left: 0px !important;
        }
      }
    }

    .esign-input__options {
      list-style: none;
      margin: 0;
      margin-top: 1rem;
      max-height: 300px;
      overflow-y: auto;
      &-header {
        display: flex;
      }
      li {
        display: flex;
        > div {
          user-select: none;
          cursor: pointer;
          &.label {
            width: 50%;
            .order-arrow {
              margin-left: 10px;
              vertical-align: middle;
              &.asc {
                transform: rotate(180deg);
              }
            }
          }
          &.email {
            width: 50%;
          }
        }
        font-size: 14px;
        display: flex;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #e5e5ea;
        &.active {
          background: rgba(50, 132, 255, 0.1);
        }
      }
    }

    $radio-button-size: 20px;
    .esign-radio-button {
      display: block;
      position: relative;
      min-width: $radio-button-size;
      min-height: $radio-button-size;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:not(:last-of-type) {
        margin-bottom: 14px;
      }

      &:hover .esign-radio-button__input ~ .esign-radio-button__check-mark {
        border-color: $primary-color-black;
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .esign-radio-button__input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .esign-radio-button__check-mark {
          background: $primary-color-white;
          border-color: $primary-color-black;
        }

        &:checked ~ .esign-radio-button__check-mark:after {
          display: block;
        }
      }

      .esign-radio-button__label {
        display: block;
        padding-left: $radio-button-size + 15px;
        line-height: $radio-button-size;
        white-space: nowrap;
      }

      .esign-radio-button__check-mark {
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 0;
        width: $radio-button-size;
        height: $radio-button-size;
        transform: translateY(-50%);
        background: $primary-color-white;
        border: 1px solid $primary-color-grey;
        border-radius: 50%;
        transition: 0.2s;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: none;
          width: calc($radio-button-size / 1.8);
          height: calc($radio-button-size / 1.8);
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: $primary-color-black;
        }
      }
    }
  }
}
