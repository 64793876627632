@import '../style/colors';
@import '../style/mixin';
@import '../style/media';
@import '../style/animations';

.app-wrapper {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @include mobile-mini {
    overflow-x: hidden;
  }

  @include mobile-small {
    overflow-x: hidden;
  }

  .home-page-main-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background-image: url('../assets/home-page-bg.webp');
    background-size: cover;
    background-position: 0 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;
      background: rgba(0, 0, 0, 0.7);
    }

    .top-nav {
      background: transparent;
      border: none;
    }

    .pages-top-nav-wrapper {
      background: transparent;
    }
  }

  .cookie-notice {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    position: fixed;
    bottom: 0;
    z-index: 99999;
    background-color: rgba(0, 7, 14, 0.7);
    padding: 15px;
    overflow: hidden;
    box-sizing: border-box;
    @include animation(fadeIn, 0.5s, ease-in-out);

    .text {
      font-size: 15px;
      color: white;
    }

    button {
      margin-left: 15px;
      padding: 5px 30px;
    }
  }
}

.pages-top-nav-wrapper {
  box-sizing: border-box;
  width: 100%;
  min-height: 500px;
  flex-grow: 1;
  padding: 30px 30px 70px 260px;
  background: white;
  display: flex;

  &--is-mobile {
    padding: 30px 50px 30px 50px;

    .side-bar-wrapper {
      left: -99%;
    }

    .burger-btn-wrapper {
      display: block !important;
    }

    @media (min-width: 320px) and (max-width: 575px) {
      padding: 30px 10px 30px 10px;
    }

    @include desktop {
      padding: 30px 10px 30px 10px !important;
    }
  }

  @include desktop {
    padding: 30px 65px 70px 260px;
  }
}

.is-common {
  padding: 65px 65px 70px 65px;

  @media (min-width: 320px) and (max-width: 575px) {
    padding: 30px 13px 30px 13px;
  }

  .side-bar-wrapper {
    display: none !important;
  }
}

.main-container {
  max-width: 1170px;
  box-sizing: border-box;

  margin: 0 auto;
  width: 100%;
}

.main-top-container {
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.impersonate {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #ff7300;
  color: #fff;
  left: 0;
  position: relative;
  z-index: 7;
  padding-top: 5px;
  padding-bottom: 5px;
  button {
    height: 30px;
    background: #ff9b49;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #fff;
    min-width: 95px;
    margin-right: 15px;
    font-weight: bold;
    border: 1px solid white;
    svg {
      margin-right: 10px;
    }
  }
}

// .home {
//   @include media-narrow {
//     padding: 10px 10px 60px 10px;
//   }

//   @include mobile-big{
//     padding: 10px 10px 60px 10px;
//   }
// }

.sub-nav {
  position: absolute;
  min-width: 340px;
  max-width: 200px;
  padding: 17px 32px;
  right: 45px;
  top: 75px;
  background: $primary-color-white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 2;
  @include animation(dropInDown, 0.01s, linear);

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;

    &--info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .name {
        font-size: 18px;
        margin-bottom: 3px;
      }

      .email {
        margin-top: 3px;
      }

      @include mobile-mini {
        align-items: center;
        margin-top: 10px;
      }
    }

    &--avatar {
      @include flex-centered();
      min-width: 81px;
      max-width: 81px;
      min-height: 81px;
      max-height: 81px;
      background: $primary-color-grey;
      border: none;
      border-radius: 50%;
      font-size: 30px;
      overflow: hidden;

      img {
        width: 100%;
      }

      &:hover {
        cursor: default;
      }
    }

    @include mobile-mini {
      flex-direction: column-reverse;
    }
  }

  &__actions {
    margin-top: 10px;

    button {
      box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 0;
      border: none;
      background: transparent;
      @include main-font-family();
      font-size: 16px;
      cursor: pointer;

      svg {
        width: 23px;
        height: 23px;
        margin-right: 14px;

        path {
          fill: $main-fill-icon;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: #efeff4;

        svg {
          width: 23px;
          height: 23px;
          margin-right: 14px;

          path {
            fill: $active-fill-icon;
          }
        }
      }
    }
  }

  @include mobile-mini {
    min-width: calc(100% - 85px) !important;
    right: 11px;
  }

  @include mobile-big {
    right: 12px;
  }

  @media (width: 812px) {
    right: 45px;
  }

  @media (width: 568px) {
    right: 12px;
  }

  @media (width: 667px) {
    right: 11px;
  }

  @include media-narrow {
    min-width: 290px;
    right: 11px;
  }
}

.sup-nav {
  width: fit-content;
  height: fit-content;
  position: absolute;
  height: auto;
  top: 65px;
  right: 125px;
  border-radius: 4px;
  padding: 11px 15px;
  box-sizing: border-box;
  background: $primary-color-white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 2;
  @include animation(dropInDown, 0.01s, linear);

  &__actions {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: flex-start;

    button {
      width: 100%;
      height: 26px;
      border: none;
      background: transparent;
      padding: 0px;
      text-align: left;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }

    a {
      width: 100%;
      height: 26px;
      border: none;
      background: transparent;
      padding: 0px;
      text-align: left;
      font-size: 14px;
      color: black;
      text-decoration: none;
      line-height: 26px !important;

      &:hover {
        text-decoration: underline;
      }
    }

    &--app-icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px;
      margin-top: 10px;
      border-top: 1px solid #dcdcdc;

      a {
        border: 1px solid #dcdcdc;
        height: 41px;
        border-radius: 4px;

        &:first-child {
          margin-right: 10px;
        }

        svg {
          #AppStore {
            path {
              &:first-child {
                fill: rgba(255, 255, 255, 0.5);
              }
            }
          }

          #Untitled {
            path {
              &:first-child {
                fill: rgba(255, 255, 255, 0.5);
              }
            }
            #Group {
              path {
                &:first-child {
                  fill: rgb(255, 51, 51) !important;
                }
              }
            }
          }
        }

        &:hover {
          svg {
            #AppStore {
              path {
                &:first-child {
                  fill: #f9fafc;
                }
              }
            }

            #Untitled {
              path {
                &:first-child {
                  fill: #f9fafc;
                }
              }
            }
          }
        }
      }
    }
  }

  @include mobile-mini {
    width: 300px;
    height: 170px;
    right: 11px;
  }

  @include mobile-small {
    width: 300px;
    height: 170px;
    right: 11px;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.justify-between {
    justify-content: space-between;
  }
}

.user-account {
  position: relative;
  display: flex;
  align-items: center;
  width: 320px;

  &--checked {
    &:after {
      position: absolute;
      content: '✔';
      right: 0;
      top: 50%;
      font-size: 24px;
      color: $primary-color-grey;
      transform: translateY(-50%);
    }
  }

  &__avatar {
    @include flex-centered();
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background: $primary-color-grey;
    border-radius: 50%;
  }

  &__text {
    .name,
    .status {
      display: block;
    }

    .status {
      font-size: 12px;
      color: #8e8e8f;
    }
  }
}

.burger-btn-wrapper {
  display: none;
  margin-right: 20px;

  .burger-btn {
    overflow: visible;
    cursor: pointer;
    fill: white;

    &--1,
    &--2,
    &--3 {
      transition: 0.2s;
      transform-origin: center center;
    }

    &.active {
      .burger-btn--1 {
        transform: translate(-7px, 7px) rotate(45deg);
      }
      .burger-btn--2 {
        transform: translateX(20px);
        opacity: 0;
      }
      .burger-btn--3 {
        transform: translate(-7px, -7px) rotate(-45deg);
      }
    }
  }

  @media (max-width: 320px) {
    margin-right: 10px;
  }

  @include mobile-mini {
    display: block;
  }

  @include mobile-small {
    display: block;
  }

  @include mobile-big {
    display: block;
  }

  @include tablet-small {
    display: block;
  }

  @include tablet-big {
    display: block;
  }
}
