@import '../../style/mixin.scss';
@import '../../style/animations';

.global-loader {
  @include flex-centered();
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 7, 14, 0.7);
  mix-blend-mode: normal;
  z-index: 999;
  overflow: hidden;
}
