@import '../../style/mixin';

$notification-types: 'success' #2fa84f, 'error' #f15642, 'info' #3195a5,
  'warning' #f1a83a, 'primary' #145388, 'secondary' #2a93d5;

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  min-width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;

  &:hover,
  &:focus {
    opacity: 1;
  }

  &:before {
    content: '';
    display: none;
  }

  &__close-icon {
    @include flex-centered();
    position: absolute;
    right: 15px;
    top: 10px;
    padding: 2px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
  }

  .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 5px 0;
  }
  .message {
    white-space: pre-line;
  }
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

@each $type, $color in $notification-types {
  .notification-#{$type} {
    background-color: $color;
    color: #fff;
    opacity: 1;

    .notification-message {
      padding-right: 40px;

      .title {
        color: #fff;
      }

      .message {
        color: #fff;
      }
    }

    &.filled {
      color: #fff;
      background-color: $color;

      .notification-message {
        .title {
          color: #fff;
        }

        .message {
          color: #fff;
        }
      }
    }
  }
}
