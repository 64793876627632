@mixin flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin text-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

@mixin main-font-family {
  font-family: 'SFProText', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

@mixin unselectable-font {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
