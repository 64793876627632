@import '../../../style/colors';
@import '../../../style/mixin';

.esign-search {
  &__input {
    box-sizing: border-box;
    width: 0;
    padding: 14px 28px 14px 16px;
    @include main-font-family();
    background: transparent;
    border: 1px solid $primary-color-black;
    border-radius: 20px;
    transition: all 0.5s ease-in-out;

    &:focus {
      width: 100%;
    }

    &--static-width {
      width: 100%;
    }
  }

  &__icon {
    @include flex-centered();
    position: absolute;
    right: 13px;
    top: 14px;
    cursor: pointer;

    svg {
      path {
        stroke: $primary-color-black;
      }
    }
  }

  &-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;

    &--light {
      .esign-search__input {
        color: $primary-color-white;
        border: 2px solid $primary-color-white;
      }

      .esign-search__icon {
        position: absolute;
        right: 13px;
        top: 14px;

        svg {
          path {
            stroke: $primary-color-white;
          }
        }
      }
    }

    input {
      height: 43px;
    }

    .esign-input__icon {
      background: #fff;
      border: 1px solid #d8d8d8;
      height: 43px;
      width: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      border-right: 0;
      box-sizing: border-box;
    }
  }

  &.esign-input-contact-wrapper {
    display: flex;
    align-items: flex-start;
    .esign-input-group-wrapper {
      display: flex;
      flex-direction: column;
      .esign-input-group {
        display: flex;
        align-items: center;
        &.active {
          .esign-input__icon {
            border: 0.5px solid #8b8b8b;
            border-right: 0px;
          }
        }
        > div {
          position: relative;
          width: 100%;
        }
        input {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 0px;
          min-width: 480px;
        }
      }
    }
  }
}
