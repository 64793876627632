@import '../../style/media';

img {
  cursor: pointer;
}

#viewerBackdrop {
  img {
    min-width: 40vw;

    @include media-below-medium {
      min-width: 80vw;
    }
  }
}
