@import '../../style/colors';
@import '../../style/mixin';
@import '../../style/media';

$form-with: 300px;

.enter-to-app-modal {
  .modal-body__close-btn {
    top: 10px;
    right: 10px;
    background: transparent;

    svg {
      path {
        stroke: #8b8b8b;
      }
    }

    &:hover {
      background: rgb(239, 239, 244);
    }
  }
}

.enter-to-app {
  max-height: 90vh;
  border-radius: 6px;
  overflow-y: scroll;
  background: $primary-color-white;

  &--min-height {
    min-height: 470px;
    display: flex;
  }

  &__title {
    padding: 20px 30px;
    font-size: 20px;

    @include media-below-medium {
      padding: 20px 15px;
    }
  }

  &__content {
    display: flex;

    @include media-below-medium {
      width: 100%;
    }
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 50px;
    background: $primary-color-white;

    @include media-below-medium {
      width: 100%;
      padding: 15px;
    }

    @include media-medium {
      padding: 40px 20px;
    }
  }

  &__form {
    .title {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 35px;
      text-align: center;
      position: relative;

      button {
        position: absolute;
        left: 0px;
        border: 0px;
        background: transparent;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: transparent;
        padding: 0px;
        box-sizing: border-box;
        float: left;
        font-size: 25px;

        svg {
          width: 18px;
          height: 18px;

          path {
            fill: #8b8b8b;
          }
        }

        &:hover {
          background: #efeff4;
        }
      }
    }

    &--current-email {
      font-size: 14px;
      display: flex;
      > span {
        overflow: hidden;
        position: relative;
        display: inline-block;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-width: 170px;
      }
    }

    &--edit-email {
      cursor: pointer;
      color: $primary-color-blue;
      margin-left: auto;
    }

    .esign-input-wrapper {
      width: $form-with;
      &.logging-in {
        width: 100%;
      }
    }

    .error_message {
      padding: 0px 10px;
      font-size: 12px;
      color: red;
      line-height: 30px;
    }

    .checkbox {
      min-width: 20px;
      min-height: 20px;

      &__mark {
        width: 20px;
        height: 20px;

        &:after {
          width: 4px;
          height: 8px;
          left: 56%;
          top: 26%;
        }
      }

      &__label {
        padding-left: 25px;
        font-size: 12px;
        color: black;
      }
    }

    .text-field {
      margin-bottom: 10px;
    }

    .esign-button[type='submit'] {
      width: 100%;
      margin-top: 25px;
    }

    .esign-modal__separator {
      font-size: 12px;
      white-space: nowrap;
      margin: 30px 0px 0px 0px;

      span {
        width: 118px;
        background: $primary-color-white;
        text-transform: lowercase;
        color: $active-fill-icon;
      }
    }

    .esign-button {
      width: 100%;
      margin-top: 25px;
    }
  }

  .with_error {
    .esign-input-wrapper {
      input {
        border: 0.5px solid red;
      }
    }
  }

  &__external-auth-btn {
    button {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 13px 9px;
      border-radius: 4px !important;
      font-family: inherit;
      font-size: 14px;
      color: #fff;
      border: 1.5px solid #e5e5ea;
      // box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.5);
      margin-bottom: 10px;

      &:hover {
        background: rgb(249, 250, 252);
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    &--google {
      button {
        background: $primary-color-white;
        color: $primary-color-black;
        box-sizing: border-box;
        justify-content: center;
        position: relative;

        span {
          width: 24px;
          height: 24px;
          margin-right: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 13px;
          top: 9px;
          transform: translate(-10%, 10%);

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &--facebook {
      span {
        .kep-login-facebook {
          font-weight: normal;
          text-decoration: none;
          text-transform: none;
          transition: none;
        }

        button {
          background: $primary-color-white;
          color: $primary-color-black;
          box-sizing: border-box;
          justify-content: center;
          position: relative;

          span {
            width: 24px;
            height: 24px;
            margin-right: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 13px;
            top: 9px;
            transform: translate(-10%, 10%);

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    &--apple {
      button {
        background: $primary-color-white;
        color: $primary-color-black;
        box-sizing: border-box;
        justify-content: center;
        position: relative;

        span {
          width: 24px;
          height: 24px;
          margin-right: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 13px;
          top: 9px;
          transform: translate(-10%, 10%);

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  &__logo {
    display: flex;
    padding-bottom: 35px;

    p {
      font-size: 10px;
    }
  }

  &__btn {
    background: transparent;
    color: black;
    @include main-font-family();
    border: none;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text {
    box-sizing: border-box;
    max-width: $form-with;
    padding: 20px 0px;
    // padding: 0 20px 20px;
    text-align: left;
    line-height: 25px;
  }

  &__footer {
    padding-top: 30px;
    font-size: 12px;
    text-align: center;
    color: #808080;

    .enter-to-app__btn {
      color: $primary-color-blue;
      font-weight: bold;
    }

    @media (max-height: 680px) {
      padding-bottom: 15px;
    }
  }
}

// style for save-document-copy popup
.save-document-copy {
  flex: 1 1 0;
  background: $primary-color-white;
  padding: 0 30px;
  border-right: 3px solid #e5e5e5;

  @include media-below-medium {
    padding: 0 15px;
  }

  @include media-narrow {
    display: none;
  }

  &-wrapper {
    padding-bottom: 30px;

    @include media-below-wide {
      width: 90vw;
    }

    @include media-below-medium {
      width: 95vw;
    }

    @include media-narrow {
      width: 90vw;
    }

    .enter-to-app__form-wrapper {
      flex: 1 1 0;
      padding: 0 30px;

      @include media-below-medium {
        padding: 0 15px;
      }
    }

    .enter-to-app__form {
      width: 100%;
    }

    .esign-input-wrapper {
      width: 100%;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 20px;
  }

  &__banner {
    margin-bottom: 25px;
    padding: 15px;
    background: #f2f2f2;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    border-radius: 6px;
  }

  &__icon-wrapper {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }

  &__benefit {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    span {
      white-space: nowrap;
      font-size: 14px;
      color: #999;
      font-weight: bold;

      @include media-below-medium {
        white-space: normal;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    .esign-button {
      @include media-below-medium {
        padding: 10px 20px;
      }
    }

    .esign-button[type='submit'] {
      width: auto;
      margin-top: 0;
      margin-left: 20px;
    }
  }
}

.checkbox {
  margin: 0px 0px !important;

  &__label {
    padding-left: 30px !important;
  }
}

.esign-recaptcha-wrapper {
  margin-top: 25px;
}
