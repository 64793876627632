@import '../../../style/colors';

.esign-radio-group {
  margin: 15px 0;

  $radio-button-size: 24px;

  .esign-radio-button {
    display: block;
    position: relative;
    min-width: $radio-button-size;
    min-height: $radio-button-size;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:not(:last-of-type) {
      margin-bottom: 14px;
    }

    &:hover .esign-radio-button__input ~ .esign-radio-button__check-mark {
      border-color: $primary-color-black;
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .esign-radio-button__input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .esign-radio-button__check-mark {
        background: $primary-color-white;
        border-color: $primary-color-black;
      }

      &:checked ~ .esign-radio-button__check-mark:after {
        display: block;
      }
    }

    .esign-radio-button__label {
      display: block;
      padding-left: $radio-button-size + 15px;
      line-height: $radio-button-size;
      white-space: nowrap;
    }

    .esign-radio-button__check-mark {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 0;
      width: $radio-button-size;
      height: $radio-button-size;
      transform: translateY(-50%);
      background: $primary-color-white;
      border: 1px solid $primary-color-grey;
      border-radius: 50%;
      transition: 0.2s;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: none;
        width: calc($radio-button-size / 1.8);
        height: calc($radio-button-size / 1.8);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: $primary-color-black;
      }
    }
  }
}
