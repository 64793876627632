@import '../../../style/colors';

.switch {
  $switch-active: #000;
  position: relative;
  width: 50px;
  height: 24px;
  cursor: pointer;

  input[type='checkbox'] {
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .switch__lever {
      background: $switch-active;
    }

    &:checked + .switch__lever:before {
      left: 25px;
    }
  }

  &__lever {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 50px;
    height: 25px;
    vertical-align: middle;
    background: #b0b0b3;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border: 1px solid #e6e6e6;
    border-radius: 15.5px;
    transition: 0.3s ease;

    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 23px;
      height: 23px;
      left: 1px;
      top: 1px;
      background: white;
      border: 1px solid #bfbfbf;
      box-sizing: border-box;
      border-radius: 50%;
      transition: left 0.3s ease;
    }

    &:hover {
      background: #99999a;
    }
  }

  .disabled {
    background: #c0c0c0 !important;
    pointer-events: none !important;

    &:before {
      background: rgb(235, 235, 235) !important;
    }
  }
}
