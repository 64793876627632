@import '../../style/colors';
@import '../../style/mixin';
@import '../../style/media';

.signers-form {
  &__title {
    display: block;
    font-weight: bold;
  }

  &__add-signer-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 25px;

    .input-field-wrapper {
      flex: 1 1 auto;
      width: 100%;

      .esign-input__error-msg {
        padding-right: 15px;
      }
    }

    .remove-signer-btn {
      @include flex-centered();
      position: absolute;
      padding: 0;
      top: 25px;
      right: -8px;
      width: 18px;
      height: 18px;
      background: $primary-color-grey;
      border: none;
      border-radius: 50%;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);

      & > svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__signers-block {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 6px;
    overflow: hidden;
  }

  &__signer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #eaeaea;

    &--padding-small {
      padding: 10px 15px;
    }

    .signer-name {
      font-size: 14px;
      color: $primary-color-black;
    }

    .signer-email {
      font-size: 14px;
      color: #8c8c8c;
    }

    .delete-btn {
      @include flex-centered();
      padding: 5px;
      background: #ebdbd9;
      border: none;
      border-radius: 50%;

      svg {
        width: 25px;
        height: auto;
      }
    }
  }

  .add-signer-btn {
    @include flex-centered();
    width: 100%;
    margin: 10px 0;
    padding: 5px;
    background: #eaeaea;
    @include main-font-family();
    color: black;
    font-size: 14px;
    border: 2px dashed $primary-color-black;
    border-radius: 6px;

    .plus-btn {
      @include flex-centered();
      margin-right: 5px;
      padding: 3px;
      background: #d3d3d3;
      border-radius: 50%;

      svg {
        transform: rotate(45deg);

        path {
          stroke: black;
        }
      }
    }
  }
}
