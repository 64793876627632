@import '../../../style/mixin';

.esign-input {
  @mixin focused-input-styles {
    top: 10px;
    left: 13px;
    font-size: 9px;
    font-weight: bold;
  }

  &__label {
    position: absolute;
    left: 0;
    top: -17px;
    font-size: 14px;
  }

  &__error-msg {
    position: absolute;
    left: 0;
    bottom: -20px;
    text-align: right;
    color: red;
    font-size: 12px;
    display: flex;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 5px;

      path {
        fill: red;
      }
    }
  }

  &__placeholder {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    @include main-font-family();
    font-size: 12px;
    pointer-events: none;
    color: #8c8c8c;
    transition: all 0.2s linear;
    i {
      color: red;
      font-style: normal;
    }
  }

  &__show-hide-btn {
    position: absolute;
    right: 5px;
    top: 56%;
    background: transparent;
    border: none;
    transform: translateY(-50%);

    svg {
      width: 18px;
      height: auto;
    }
  }

  &-wrapper {
    position: relative;
    margin-top: 20px;
    &--valid {
      input {
        border: 0.5px solid #3284ff !important;
      }
    }
    &--focused {
      .esign-input__placeholder {
        @include focused-input-styles();
      }
    }

    &--error {
      .esign-input__placeholder {
        color: red;
      }

      input {
        color: red;
        border: 0.5px solid red !important;
      }
    }

    input {
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      padding: 15px 12px 7px;
      background: #ffffff;
      @include main-font-family();
      font-size: 14px;
      border: 0.5px solid #d8d8d8;
      border-radius: 8px;

      &:hover {
        border: 0.5px solid #8b8b8b;
      }

      &:disabled {
        background: #eaeaea;
      }

      // hide native placeholder
      &::placeholder {
        color: transparent;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }

      &:focus ~ .esign-input__placeholder,
      &:disabled ~ .esign-input__placeholder,
      &:not(:focus):valid ~ .esign-input__placeholder {
        @include focused-input-styles();
      }
    }
  }
}
