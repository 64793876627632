@import 'colors';
@import 'mixin';

.esign-button {
  @include flex-centered();
  height: 40px;
  padding: 12px 15px;
  @include main-font-family();
  text-decoration: none;
  border: 1px solid rgba($color: #8a8a8a, $alpha: 0.25);
  border-radius: 8px;
  white-space: nowrap;
  font-size: 16px;

  svg {
    width: 19px;
    height: 19px;
    margin-right: 12px;
  }

  &--padding-side-small {
    padding: 10px 15px;
  }

  &--padding-small {
    padding: 5px 25px;
  }

  &--full-width {
    width: 100%;
  }

  &--link {
    border: transparent !important;
    background: transparent !important;
    color: #3284ff !important;

    &:hover {
      border: transparent !important;
      background: transparent !important;
      text-decoration: underline;
    }
  }

  &--white {
    font-weight: normal;
    color: $primary-color-black;
    background: $primary-color-white;

    &:hover {
      color: $primary-color-black;
    }
  }

  &--light {
    color: black;
    background: #ffffff;
    border: 1px solid rgba($color: #8a8a8a, $alpha: 0.25);

    svg {
      path {
        fill: black;
      }
    }

    &:hover {
      background: #f9fafc;
    }

    &:disabled {
      background: rgb(239, 239, 242);
      color: rgb(119, 119, 121);
      cursor: not-allowed;

      svg {
        path {
          fill: rgb(119, 119, 121);
        }
      }
    }
  }

  &--blue {
    color: white;
    background: #3284ff;

    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      background: #3273ff;
    }

    &:disabled {
      background: #a4c9ff;
      color: white;
      border-color: transparent;
      cursor: not-allowed;

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  &--red {
    color: rgb(255, 12, 24);
    background: #ffffff;
    border: 1px solid rgba($color: #8a8a8a, $alpha: 0.25);

    &:hover {
      background: #f2f2f2;
    }

    &:disabled {
      background: rgb(239, 239, 242);
      color: rgb(255, 143, 142);
      border: 1px solid transparent;
      cursor: not-allowed;

      svg {
        path {
          fill: rgb(119, 119, 121);
        }
      }
    }
  }

  &--transparent-white {
    background: transparent;
    color: white;
    border: 1px solid white;

    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      background: #f2f2f72e;
    }
  }

  &--transparent-red {
    background: transparent;
    color: #ff594b;
    border: none;
    padding: 0px;

    &:hover {
      color: #ff594b;
      text-decoration: underline;
    }

    &:disabled {
      color: grey;
      cursor: not-allowed;
    }
  }

  &--transparent-blue {
    background: transparent;
    border: none;
    color: $primary-color-blue;
    font-weight: normal;
    box-shadow: none;
    padding: 0px;
    font-size: 16px;

    &:hover {
      background: transparent;
      color: $primary-color-blue;
      text-decoration: underline;
    }
  }
}

.esign-select {
  &__control {
    width: 118px;
    height: 43px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: $primary-color-blue !important;
    border-radius: 8px !important;
    cursor: pointer !important;
  }

  &__single-value {
    color: white !important;
  }

  &__value-container {
    justify-content: flex-end;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    padding: 8px 14px 8px 0px !important;

    svg {
      fill: white;
    }
  }

  &__menu {
    width: 150px !important;
    border-radius: 8px !important;
    overflow: hidden !important;

    &-list {
      max-height: fit-content !important;
    }
  }

  &__option {
    display: flex !important;
    align-items: center !important;
    background-color: white !important;
    color: black !important;
    font-size: 14px !important;
    cursor: pointer !important;

    &:hover {
      background-color: rgba(229, 229, 229, 0.7) !important;
    }

    svg {
      width: 18px;
      height: fit-content;
      margin-right: 10px;
    }

    .sign-request-icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: rgb(255, 12, 24);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      position: absolute;
      right: 10px;
      transform: translate(0px, 2px);
    }
  }
}
