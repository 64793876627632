@import '../../style/colors';
@import '../../style/mixin';
@import '../../style/media';
@import '../../style/animations';

.side-bar-wrapper {
  min-width: 230px;
  max-width: 230px;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  transition: all 0.5s ease 0s;
  margin-right: 30px;
  background: #f9fafc;
  &.side-bar-wrapper-mobile {
    position: fixed;
  }
  ul {
    padding-top: 97px;
    &.impersonate-sidebar {
      padding-top: 137px;
    }
    .buttons-block {
      max-width: 232px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px 30px 10px;
      &.full-width {
        max-width: 100%;
      }
      .esign-button {
        &:first-child {
          width: 65%;
        }
        &.new-template--button {
          width: 100%;
          svg path {
            fill: none;
          }
          svg g g path {
            fill: white;
          }
        }
      }

      .esign-select-list {
        &__value-container {
          width: 30px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0 0 5px;
        }

        &__single-value {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__indicators {
          margin-right: 0px;
        }

        &__indicator {
        }

        &__menu {
          width: 160px;

          &-list {
            padding: 5px 0px;
          }
        }

        &__option {
          width: 100%;
          height: 30px;
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
          background: transparent !important;

          &:hover {
            background: #f2f2f2 !important;
          }

          svg {
            width: 18px;
            height: 18px;
            margin-right: 15px;
          }
        }
      }
    }

    .item_link {
      width: 100%;
      min-height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: transparent;
      border-radius: 0 8px 8px 0;
      text-decoration: none;
      color: black;
      font-size: 18px;

      p {
        display: flex;
        align-items: center;
        margin-left: 50px;

        svg {
          width: 18px;
          min-width: 18px;
          height: 18px;
          min-height: 18px;
          margin-left: 0px;
          margin-right: 15px;
        }

        label {
          max-width: 55%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .tooltip {
          display: none;
        }

        &:hover {
          position: relative;

          .tooltip {
            display: block !important;
            width: auto;
            height: auto;
            background: black;
            position: absolute;
            left: 50%;
            top: -50%;
            transform: translate(-50%, -50%);
            padding: 5px 7px;
            max-width: 150px;
            white-space: break-spaces;
            text-align: center;
          }
        }
      }

      svg {
        width: 18px;
        height: 18px;
        margin-left: 27px;
        margin-right: 10px;

        path {
          fill: $active-fill-icon;
        }

        circle {
          fill: $active-fill-icon;
        }
      }

      &:hover {
        span {
          opacity: 1;
        }
      }

      span {
        position: absolute;
        left: 5px;
        opacity: 0;

        svg {
          margin: 0;
          height: 10px;

          path {
            fill: $primary-color-black;
          }
        }
      }

      &--active {
        background: #efeff4;
        font-weight: 600;
        position: relative;
        .arrow-setting-opened {
          transform: rotate(90deg);
        }
        .arrow-setting-closed {
          transform: rotate(0deg);
        }
      }

      &--active--border {
        border-top-right-radius: 0px;
        background: transparent;
        &::before {
          position: absolute;
          content: '';
          height: 1px;
          width: 100%;
          background: rgb(229, 229, 234);
          top: 0;
        }
      }
      &--active--inbox {
        border-top-right-radius: 0px;
        background: transparent;
      }

      &--over {
        background: rgba(50, 132, 255, 0.063) !important;
        position: relative;
      }

      &:hover {
        cursor: pointer;
        background: #efeff4;

        svg {
          path {
            fill: $active-fill-icon;
          }

          circle {
            fill: $active-fill-icon;
          }
        }
      }
    }

    .add_company {
      position: relative;

      &::before {
        content: '+';
        position: absolute;
        left: 15%;
      }

      @include mobile-mini {
        &::before {
          left: 10%;
        }
      }

      @include mobile-small {
        &::before {
          left: 10%;
        }
      }

      @include mobile-big {
        &::before {
          left: 10%;
        }
      }

      @include tablet-small {
        &::before {
          left: 15%;
        }
      }
    }

    .not-expand {
      height: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      margin-top: 0px;

      p {
        width: 100%;
        min-height: 32px;
        margin-left: 83px;
      }
    }
  }

  .expand {
    height: auto;
    max-height: 192px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 0px;

    hr {
      width: 100%;
      height: 5px;
      background: black;
    }

    p {
      width: 100%;
      min-height: 32px;
      margin-left: 55px;
    }

    .item_link--has-icon {
      p {
        margin-left: 42px;
      }
    }
  }

  ul {
    margin-bottom: 5px;
    margin-top: 1px;
  }
}

hr {
  background: rgb(229, 229, 234);
  border: none;
  height: 1px;
}

.documents-library-link {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  font-size: 12px;
  color: gray;
  position: relative;
  bottom: 0;
  .esign-button {
    width: 100%;
    justify-content: flex-start;
    font-size: 15px;
    padding: 0px;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 12px;

      g {
        fill: #3284ff;
      }
    }
  }
}

.show-sidebar {
  left: 0 !important;
  background: white;
  transition: all 0.5s ease 0s;

  ul {
    padding-top: 97px;
  }

  @include mobile-mini {
    min-width: 100%;
    box-shadow: 5px 0px 8px 0px rgba(34, 60, 80, 0.2);
  }

  @include mobile-small {
    min-width: 100%;
    box-shadow: 5px 0px 8px 0px rgba(34, 60, 80, 0.2);
  }

  @include mobile-big {
    min-width: 250px;
    box-shadow: 5px 0px 8px 0px rgba(34, 60, 80, 0.2);
  }

  @include tablet-small {
    min-width: 250px;
    box-shadow: 5px 0px 8px 0px rgba(34, 60, 80, 0.2);
  }

  @include tablet-big {
    min-width: 250px;
    box-shadow: 5px 0px 8px 0px rgba(34, 60, 80, 0.2);
  }

  @include desktop {
    min-width: 250px;
    box-shadow: 5px 0px 8px 0px rgba(34, 60, 80, 0.2);
  }
}

.side-bar-controller {
  width: 200%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 3;
  display: block;
  opacity: 0;
  background: rgba(0, 7, 14, 0);
  transition: all 0.5s ease 0s;
  pointer-events: none;
}

.show-controller {
  width: calc(100% - 250px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 250px;
  z-index: 3;
  display: block;
  opacity: 1;
  background: rgba(0, 7, 14, 0.7);
  transition: all 0.5s ease 0s;
  pointer-events: inherit;

  @include mobile-mini {
    left: 100%;
    width: 0;
  }

  @include mobile-small {
    left: 100%;
    width: 0;
  }

  @include desktop {
    // display: none;
  }
}
