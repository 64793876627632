.esign-contact-input {
  position: relative;
  &-autocomplete {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid #d8d8d8;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 111;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    li {
      display: flex;
      flex-direction: column;
      padding: 6px 12px;
      width: 100%;
      .label {
        .esign-contact-input__label {
          font-size: 14px;
        }
      }
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
      &.active {
        background: rgba(0, 0, 0, 0.05);
      }
      .email {
        font-size: 12px;
        color: #afafb5;
      }
    }
  }
}
