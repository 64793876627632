$primary-color-black: #000;
$primary-color-white: #fff;
$primary-color-grey: #b0b0b0;
$primary-color-grey-light: #f9fafc;
$primary-color-bg: #f9fafc;
$primary-color-blue: #3284ff;
$primary-color-green: #059e1d;
$secondary-color-grey: #5e5e5e;

$main-fill-icon: #d8d8d8;
$active-fill-icon: #8b8b8b;
$active-element-color: #ececec;
$active-switch-color: #ececec;
$danger: #ff453a;
$danger-light: #ee675f;
