.system-banner {
  padding: 12px;
  background-color: #ffdb74;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  &.portable {
    position: absolute;
    z-index: 111;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
  }
  span {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    flex: 1;
  }
  .close {
    cursor: pointer;
    path {
      stroke: #000;
    }
    &:hover {
      path {
        stroke: #333;
      }
    }
  }
}
